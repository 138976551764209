import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from './store';
// import axios from 'axios';
import ElementUI from 'element-ui';
import VueLazyload from 'vue-lazyload';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/assets/css/normalize.css';
import { HttpService } from './service/http';
import { judgeLogin } from './service/commonRequest';
import apiList from '../src/utils/api';
import { v4 as uuids4 } from 'uuid';
import * as echarts from 'echarts';
import { getCookieName, isMobile } from '@/utils/utils';

Vue.config.productionTip = false;
// Vue.prototype.$axios = axios;
Vue.prototype.$httpService = new HttpService(); // 挂载服务
Vue.prototype.$isLogin = judgeLogin;
Vue.prototype.$apiList = apiList;
Vue.prototype.$uuids4 = () => {
  return uuids4();
};
Vue.prototype.$echarts = echarts;
Vue.prototype.$apiProxy = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';

Vue.use(ElementUI);
Vue.use(VueLazyload);

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
});

window.addEventListener('unhandledrejection', (event: any) => { // 禁用Promise reject输出控制台
  if (event) {
    event.preventDefault();
  }
});
const hmt: any = (window as any).hmt || [];
(window as any).hmt = hmt;
(() => {
  if (window.location.hostname === "cloud.morewis.com") {
    const hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?8d7b391f9828b7e8adf2418daef95e9c";
    const s: any = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  }
})();

router.beforeEach((to: any, from: any, next: any) => {
  if (isMobile()) {
    const appUrl: any = process.env.VUE_APP_URL;
    let mobileUrl = "https://mobile.morewiscloud.com";

    if (appUrl.indexOf("twww") !== -1 || appUrl.indexOf("tcloud") !== -1) {
      mobileUrl = "https://tmobile.morewiscloud.com";
    } else if (appUrl.indexOf("dwww") !== -1 || appUrl.indexOf("dcloud") !== -1) {
      mobileUrl = "https://tmobile.morewiscloud.com";
    } else if (appUrl.indexOf("swww") !== -1 || appUrl.indexOf("scloud") !== -1) {
      mobileUrl = "https://smobile.morewiscloud.com";
    } else if (appUrl.indexOf("www") !== -1 || appUrl.indexOf("cloud") !== -1) {
      mobileUrl = "https://mobile.morewiscloud.com";
    }
    window.location.href = mobileUrl + '/mall/index';
    return;
  }
  // MC嵌套页面不走以下清空缓存流程
  if (!(to.query.embed && to.query.embed === 'mc')) {
    // 先判断是否存在cookie，不存在就清空缓存
    const legacyCookieName = localStorage.getItem('legacy_cookieName') as string;
    if (!getCookieName(legacyCookieName)) {
      localStorage.clear();
    }
  }
  if (to.path) {
    if (hmt) {
      const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
      hmt.push(['_trackPageview', baseUrl + to.fullPath]);
    }
  }
  // 判断没有token的情况下，内部页面返回login
  if (!to.meta.independentPage
      && localStorage.getItem('id_token') === null ) {
    router.push("/login").catch((err) => err);
    return;
  }

  // 跳转的时候判断是否有token,如果没有则去登录
  const userType = localStorage.getItem("userType");

  if (userType && userType === "1" && (to.path.indexOf('company') > -1 || to.path.indexOf('sale') > -1 ) ) {
    router.push("/person").catch((err) => err);
    return;
  }
  if (userType && userType === "2" && to.path.indexOf('person') > -1) {
    router.push("/company").catch((err) => err);
    return;
  }
  if (to.path === "/company/docCenter") {
    router.push("/companyDoc").catch((err) => err);
    return;
  } else if (to.path === "/person/docCenter") {
    router.push("/personDoc").catch((err) => err);
    return;
  } else {
    next();
  }
  // 指定当前菜单选中项
  if (to.name !== "Login" && to.name !== "FindPwd" && to.name !== "Register" && to.name !== "ResetPwd") {
    const currentPath = localStorage.getItem("currentPath") === null ? 'companyHome' : localStorage.getItem("currentPath");
    const data: any = localStorage.getItem("menusData");
    const menus = JSON.parse(data);
    const toName = (to.name).replace((to.name)[0], (to.name)[0].toLowerCase());
    let hasMenu: boolean = false;
    let str: any;
    if (menus !== null) {
      menus.filter((item: any) => {
          if (item.childs !== undefined) {
            const childs = item.childs;
            childs.filter((childItem: any) => {
              if (childItem.entity.name === toName) {
                hasMenu =  true;
              }
            });
          } else {
            if (item.entity.name === toName) {// 企业首页
              hasMenu =  true;
            }
          }
      });
      str = hasMenu ? to.path : currentPath;
    } else {
      str = to.path;
    }
    str = to.path;
    const strArr = str.split("\/");
    // const subTarget = str.substring(str.lastIndexOf("\/") + 1, str.length);
    // /sale/saleHome
    // /company/resource/checkDbType
    // 为了都能取到第二级来选中菜单 saleHome resource
    const subTarget = strArr[2];
    let target = (str === '/') ? currentPath : subTarget;
    // 代客下单时，菜单选中报备客户
    if ( to.name === 'ResourceBuy' && to.query.userData  && to.query.userData + '' !== '') {
      target = 'reportCustomer';
    // 消息中心时，不选中
    } else if (to.name === "Message") {
      target = '';
    }
    store.commit("saveCurrPath", target);
  }
});

const VM = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default VM;
