import VM from '@/main';
import { isValidToken } from '@/utils/utils';

/**
 * judgeLogin 判断是否登录；本地缓存的token存在并且时间过期时，试图拿一次最新的过期时间，如果可以则更新过期时间，如果过期则标记未登录状态
 */
export async function judgeLogin() {
    const accessToken: any = localStorage.getItem('id_token');
    const hasToken: boolean = accessToken !== null
        && accessToken !== undefined
        && accessToken !== '';
    let login = true;
    // 没有token，直接默认未登录
    if (!hasToken) {
        return false;
    }
    // 登录状态有效，已登录
    if (isValidToken()) {
        return true;
    }
    // 登录第一次异常，拿一次新的token，再判断状态
    await VM.$httpService.getData({
        notLoading: true,
    }, '/apiProxy/api/frontend/passport/token')
    .catch(() => {
        if (isValidToken()) {
            login = true;
        } else {
            login = false;
        }
    })
    .finally(() => {
        if (isValidToken()) {
            login = true;
        } else {
            login = false;
        }
    });
    return login;
}
