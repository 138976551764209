import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import person from './person/index';
import company from './company/index';
import sale from './sale/index';
import singlePage from './singlePage';
Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
      path: '/',
      redirect: {
        name: 'Index',
      },
    },
    {
      path: '/index',
      name: 'Index',
      component: () => import(/* webpackChunkName: "index" */ '@/views/singlePage/index/index.vue'),
      meta: {
        independentPage: true,
      },
    },
    {
      path: '/login',
      name: 'Login',
      // component: Login,
      component: () => import(/* webpackChunkName: "login" */ '@/views/login/Login.vue'),
      meta: {
        independentPage: true,
      },
    },
    {
      path: '/findPwd',
      name: 'FindPwd',
      component: () => import(/* webpackChunkName: "login" */ '@/views/login/FindPwd.vue'),
      meta: {
        independentPage: true,
      },
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import(/* webpackChunkName: "login" */ '@/views/login/Register.vue'),
      meta: {
        independentPage: true,
      },
    },
    {
      path: '/resetPwd',
      name: 'ResetPwd',
      component: () => import(/* webpackChunkName: "login" */ '@/views/login/ResetPwd.vue'),
      meta: {
        independentPage: true,
      },
    },
    ...singlePage,
    ...person,
    ...company,
    ...sale,
    // 企业文档中心
    {
      path: '/companyDoc',
      name: 'CompanyDoc',
      component: () => import(/* webpackChunkName: "docCenter" */ '@/views/company/docCenter/index.vue'),
    },
    // 个人文档中心
    {
      path: '/personDoc',
      name: 'PersonDoc',
      component: () => import(/* webpackChunkName: "docCenter" */ '@/views/person/docCenter/index.vue'),
    },
    {
      path: '/linkInvalid',
      name: 'LinkInvalid',
      component: () => import(/* webpackChunkName: "LinkInvalid" */ '@/views/LinkInvalid.vue'),
      meta: {
        independentPage: true,
      },
    },
    {
      path: '/500',
      name: '500',
      component: () => import(/* webpackChunkName: "errPage" */ '@/views/500.vue'),
    },
    {
      path: '/403',
      name: '403',
      component: () => import(/* webpackChunkName: "errPage" */ '@/views/403.vue'),
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "errPage" */ '@/views/NotFound.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
