export default[
    {
        path: 'resource',
        name: 'Resource',
        component: () => import(/* webpackChunkName: "resourceManage" */ '@/views/company/resourceManage/index.vue'),
    },
    {
        path: 'resource/resourceBuy',
        name: 'ResourceBuy',
        component:
            () => import(/* webpackChunkName: "resourceManage" */ '@/views/company/resourceManage/buy/index.vue'),
    },
    {
        path: 'resource/checkSysDetal',
        name: 'CheckSysDetal',
        component:
            () => import(/* webpackChunkName: "resourceManage" */ '@/views/company/resourceManage/component/CheckSysDetail.vue'),
    },
    {
        path: 'resource/checkDbType',
        name: 'CheckDbType',
        component:
            () => import(/* webpackChunkName: "resourceManage" */ '@/views/company/resourceManage/component/CheckDbType.vue'),
    },
    {
        path: 'resource/checkUserNum',
        name: 'CheckUserNum',
        component:
            () => import(/* webpackChunkName: "resourceManage" */ '@/views/company/resourceManage/component/CheckUserNum.vue'),
    },
    {
        path: 'resource/checkMonitor',
        name: 'CheckMonitor',
        component:
            () => import(/* webpackChunkName: "resourceManage" */ '@/views/company/resourceManage/component/CheckMonitor.vue'),
    },
];
