export default[
    {
        path: '/mall',
        name: 'Mall',
        component: () => import(/* webpackChunkName: "mall" */ '@/views/singlePage/mall/index.vue'),
        meta: {
            independentPage: true,
        },
    },
    {
        path: '/documentCenter',
        name: 'DocumentCenter',
        component:
            () => import(/* webpackChunkName: "documentCenter" */ '@/views/singlePage/documentCenter/index.vue'),
        meta: {
            independentPage: true,
        },
    },
    {
        path: '/documentPage',
        name: 'DocumentPage',
        component:
            () => import(/* webpackChunkName: "documentCenter" */ '@/views/singlePage/documentCenter/documentPage.vue'),
        meta: {
            independentPage: true,
        },
    },
    {
        path: '/developerCenter',
        name: 'DeveloperCenter',
        component:
            () => import(/* webpackChunkName: "developerCenter" */ '@/views/singlePage/developerCenter/index.vue'),
        meta: {
            independentPage: true,
        },
    },
    {
        path: '/downloadRes',
        name: 'DownloadRes',
        component:
            () =>
            import(/* webpackChunkName: "developerCenter" */ '@/views/singlePage/developerCenter/downloadRes.vue'),
        meta: {
            independentPage: true,
        },
    },
    {
        path: '/industryLibrary',
        name: 'IndustryLibrary',
        component:
            () => import(/* webpackChunkName: "industryLibrary" */ '@/views/singlePage/industryLibrary/list.vue'),
        meta: {
            independentPage: true,
            keepAlive: true,
        },
    },
    {
        path: '/sampleCenter',
        name: 'SampleCenter',
        component:
            () => import(/* webpackChunkName: "sampleCenter" */ '@/views/singlePage/sampleCenter/index.vue'),
        meta: {
            independentPage: true,
        },
    },
    {
        path: '/experienceCenter',
        name: 'ExperienceCenter',
        component:
            () => import(/* webpackChunkName: "experienceCenter" */ '@/views/singlePage/experienceCenter/index.vue'),
        meta: {
            independentPage: true,
        },
    },
    {
        path: '/detail',
        name: 'Detail',
        component: () => import(/* webpackChunkName: "product" */ '@/views/singlePage/product/detail.vue'),
        meta: {
            independentPage: true,
        },
    },
    {
        path: '/emulateDetail',
        name: 'EmulateDetail',
        component:
            () => import(/* webpackChunkName: "industryLibrary" */ '@/views/singlePage/industryLibrary/component/EmulateDetail.vue'),
        meta: {
            independentPage: true,
        },
    },
    {
        path: '/goods/detail',
        name: 'GoodsDetail',
        component: () => import(/* webpackChunkName: "product" */ '@/views/singlePage/product/goods/detail.vue'),
        meta: {
            independentPage: true,
        },
    },
    {
        path: '/demand/detail',
        name: 'DemandDetail',
        component: () => import(/* webpackChunkName: "product" */ '@/views/singlePage/product/demand/detail.vue'),
        meta: {
            independentPage: true,
        },
    },
];
