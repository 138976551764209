import axios from 'axios';
import Qs from 'qs';
import { useMessage} from '@/utils/message'; // 方便弹出吐司
import Vue from 'vue';
import router from '@/router/index';
import { Loading } from 'element-ui';

// loading框设置局部刷新，且所有请求完成后关闭loading框
let loadingInstance: any = null; // loading 实例
let needLoadingRequestCount = 0; // 当前正在请求的数量

function showLoading() {
  const main = document.querySelector('#app'); // 获取dom节点
  const index = document.querySelector('#singlePage'); // 获取首页
  if (main) {
     if (needLoadingRequestCount === 0 && !loadingInstance) {
        loadingInstance = Loading.service({
          lock: true,
          target: '#app',
          text: '正在加载...',
          background: `rgba(0,0,0,${index ? '0' : '0.3'})`,
          spinner: 'el-icon-loading',
        });
     }
     needLoadingRequestCount++;
  }
}
function closeLoading() {
  Vue.nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
     needLoadingRequestCount--;
     needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); // 保证大于等于0
     if (needLoadingRequestCount === 0) {
        if (loadingInstance) {
          // 合并loading请求效果 避免重复请求
          setTimeout(() => {
            if (loadingInstance) {
              loadingInstance.close();
            }
            loadingInstance = null;
          }, 200);
        }
     }
  });
}

function generateReqKey(config: any) {
  const { method, url, params, data } = config;
  let paramsHandle: any = {};  // 后续保存的item params 数据有可能转化成 string
  if (params && Object.prototype.toString.call(params) === '[object String]') {
    paramsHandle = JSON.parse(params);
  } else {
    paramsHandle = params;
  }
  let dataHandle: any = {}; // 后续保存的item data 数据有可能转化成 string
  if (data && Object.prototype.toString.call(data) === '[object String]') {
    dataHandle = JSON.parse(data);
  } else {
    dataHandle = data;
  }
  return [method, url, Qs.stringify(paramsHandle), Qs.stringify(dataHandle)].join("&");
}
// 取消重复请求操作
const urlArr: any = [];
function addUrlTime(configDate: any) {
  if (configDate.length === 0) {
    urlArr.push(configDate);
  } else {
    let isFlag: boolean = false;
    let currItem: any = null;
    let currIndex: number = -1;
    urlArr.filter((item: any, index: number) => {
      if (generateReqKey(item) === generateReqKey(configDate)) {
          isFlag = true;
          currItem = item;
          currIndex = index;
          return ;
      }
    });
    if (!isFlag) {
      urlArr.push(configDate);
    } else {
      const compareTime = (new Date()).getTime() - (currItem.urlTime).getTime();
      if (compareTime < 500) {
        urlArr.splice(currIndex, 1);
        // 取消请求
        const map = new Map();
        configDate.cancelToken = new axios.CancelToken((cancel) => {
          map.set(generateReqKey(configDate), cancel);
        });
        const cancelToken = map.get(generateReqKey(configDate));
        cancelToken(generateReqKey(configDate));
      } else {
        urlArr[currIndex] = configDate;
      }
    }
  }
}

export class Interceptors {
  public instance: any;

  constructor() {
    // 创建axios实例
    this.instance = axios.create({timeout: 1000 * 60});
    // this.instance.defaults.baseURL = 'https://twww.morewiscloud.com';
    // 初始化拦截器
    this.initInterceptors();
  }

  // 为了让http.ts中获取初始化好的axios实例
  public getInterceptors() {
    return this.instance;
  }
  // 初始化拦截器
  public initInterceptors() {
    // 设置post请求头
    // this.instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    /**
     * 请求拦截器
     * 每次请求前，如果存在token则在请求头中携带token
     */
    this.instance.interceptors.request.use(
      (config: any) => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        const getNotLoading = config.method === 'get' && config.params && config.params.notLoading;
        const notGetNotLoading = config.method !== 'get' && config.data && config.data.notLoading;
        if (getNotLoading || notGetNotLoading) {
          // ...
        } else {
          showLoading();
        }
        config = Object.assign(config, {urlTime: new Date()});
        addUrlTime(config); // 取消重复请求操作
        const token = localStorage.getItem('id_token');
        if (token) {
          config.headers.Authorization = 'Bearer ' + token;
        }
        let url = config.url;
        if (config.method === 'get' && config.params) {
          url += '?';
          const keys = Object.keys(config.params);
          for (const key of keys) {
            if (config.params[key] !== null && config.params[key] !== undefined) {
              url += `${key}=${encodeURIComponent(config.params[key])}&`;
            }
          }
          url = url.substring(0, url.length - 1);
          config.params = {};
        }
        config.url = url;
        return config;
      },
      (error: any) => {
        closeLoading();
        console.log(error);
      },
    );


    // 响应拦截器
    this.instance.interceptors.response.use(
      // 请求成功
      (res: any) => {
        closeLoading();
        if (res.headers.authorization) {
          localStorage.setItem('id_token', res.headers.authorization);
        } else {
          if (res.data && res.data.data && res.data.data.accessToken) {
            localStorage.setItem('id_token', res.data.data.accessToken);
            localStorage.setItem('id_token_expiresTime', res.data.data.expiresTime);
          }
          if (res.data && res.data.data && res.data.data.legacyToken) {
            localStorage.setItem('session_token', res.data.data.legacyToken);
            localStorage.setItem('legacy_cookieName', res.data.data.legacyCookieName);
            localStorage.setItem('legacy_cookieDomain', res.data.data.legacyCookieDomain);
            localStorage.setItem('legacy_cookieTimeout', res.data.data.legacyCookieTimeout);
          }
        }

        if (res.status === 200) {
          return Promise.resolve(res.data);
        } else {
          this.errorHandle(res);
          return Promise.reject(res.data);
        }
      },
      // 请求失败
      (error: any) => {
        closeLoading();
        const {response} = error;
        if (axios.isCancel(error)) {
          useMessage(3, '已取消重复的请求：您1秒内提交了多次，将提交初次的请求数据！');
        } else {
          // 添加异常处理
          if (response) {
            // 请求已发出，但是不在2xx的范围
            this.errorHandle(response);
            return Promise.reject(response.data);
          } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            useMessage(3, '网络连接异常,请稍后再试!');
          }
        }
        return Promise.reject(response.data);
      });
  }


  /**
   * http握手错误
   * @param res 响应回调,根据不同响应进行不同操作
   */
  private errorHandle(res: any) {
    // 状态码判断
    switch (res.status) {
      case 401:
        break;
      case 403:
        router.push("403").catch((err) => err);
        break;
      case 404:
        useMessage(3, '请求的资源不存在');
        break;
      case 500:
        router.push("500").catch((err) => err);
        break;
      default:
        // useMessage(3, '连接错误');
        useMessage(3, res.data.message);
        break;
    }
  }
}
