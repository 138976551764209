export default[
    {
        path: 'reportCustomer',
        name: 'reportCustomer',
        component: () =>
        import(/* webpackChunkName: "productAuthorize" */ '@/views/company/productAuthorize/reportCustomer/index.vue'),
    },
    {
        path: 'projectList',
        name: 'projectList',
        component: () =>
        import(/* webpackChunkName: "productAuthorize" */ '@/views/company/productAuthorize/projectList/index.vue'),
    },
    {
        path: 'authorize',
        name: 'authorize',
        component: () =>
        import(/* webpackChunkName: "productAuthorize" */ '@/views/company/productAuthorize/authorize/index.vue'),
    },
    {
        path: 'deviceDriveBuy',
        name: 'deviceDriveBuy',
        component: () =>
        import(/* webpackChunkName: "about" */ '@/views/company/productAuthorize/deviceDriveBuy/index.vue'),
    },
    {
        path: 'deviceDriveBuy',
        name: 'deviceDriveBuy',
        component: () =>
        import(/* webpackChunkName: "about" */ '@/views/company/productAuthorize/deviceDriveBuy/index.vue'),
    },
];
