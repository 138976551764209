export default[
    {
        path: 'perMaterial',
        name: 'PerMaterial',
        component: () => import(/* webpackChunkName: "accountManage" */ '@/views/person/accountManage/material/index.vue'),
    },
    {
        path: 'binding',
        name: 'Binding',
        component:
            () => import(/* webpackChunkName: "accountManage" */ '@/views/person/accountManage/binding/index.vue'),
    },
    {
        path: 'perSecurity',
        name: 'PerSecurity',
        component: () => import(/* webpackChunkName: "accountManage" */ '@/views/person/accountManage/security/index.vue'),
    },
];
