/**
 * 时间戳转换
 */
export function formatTime(timestamp: number | string, format: string = "yyyy-MM-dd hh:mm:ss"): string {
    const date = new Date(timestamp);
    const dateFormat: any = {
        "y+": date.getFullYear(),
        "M+": date.getMonth() + 1,
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
    };

    if (/(y+)/i.test(format)) {
        format = format.replace(RegExp.$1, (dateFormat["y+"] + "").substr(4 - RegExp.$1.length));
    }
    for (const key in dateFormat) {
        if (new RegExp("(" + key + ")").test(format)) {
            format = format.replace(
                RegExp.$1, RegExp.$1.length === 1 ?
                dateFormat[key] :
                ("00" + dateFormat[key]).substr(("" + dateFormat[key]).length));
        }
    }
    return format;
}

/**
 * 日期加减计算
 * @param date 基础时间，默认为当前日期
 * @param num 要计算的数值
 * @param type 计算类型，默认天：Y: 年 M: 月；D: 日；H: 小时；m: 分钟；s: 秒钟
 * @param format 结果格式化，默认yyyy-MM-dd
 * @returns 计算后的时间
 */
export function subDate(date: any, num: number = 0, type: string = 'D', format: string = 'yyyy-MM-dd') {
    date = date || new Date();
    if (type === 'Y') {
        date = addmulMonth(formatTime(date.toString(), 'yyyy-MM-dd hh:mm:ss'), 12 * num);
        // date.setFullYear(date.getFullYear() + num);
    } else if (type === 'M') {
        date = addmulMonth(formatTime(date.toString(), 'yyyy-MM-dd hh:mm:ss'), num);
        // date.setMonth(date.getMonth() + num);
    } else if (type === 'D') {
        date.setDate(date.getDate() + num);
    } else if (type === 'H') {
        date.setHours(date.getHours() + num);
    } else if (type === 'm') {
        date.setMinutes(date.getMinutes() + num);
    } else if (type === 's') {
        date.setSeconds(date.getSeconds() + num);
    }
    return formatTime(date.toString(), format);
}
/**
 * 计算给定日期增加N个自然月后的日期
 *
 * @param dtstr    给定日期，格式 2016-01-30 01:01:01
 * @param n    需要增加的月数
 * @returns
 */
function addmulMonth(dtstr: string, n: number) { // n个月后
    const arr = dtstr.split(" ");
    const s: any = arr[0].split("-");
    const d: any = arr[1].split(":");
    const yy = parseInt(s[0], 10);
    const mm = parseInt(s[1], 10) - 1;
    const dd = parseInt(s[2], 10);
    let dt = new Date(yy, mm, dd, d[0], d[1], d[2]);
    dt.setMonth(dt.getMonth() + n);
    if ((dt.getFullYear() * 12 + dt.getMonth()) > (yy * 12 + mm + n)) {
        dt = new Date(dt.getFullYear(), dt.getMonth(), 0, d[0], d[1], d[2]);
    }
    return dt;
}

/**
 * 日期相差计算，得到相差天数
 * @param date 传过来时间和当前时间计算
 * @returns 计算后的相差的天数
 */
export function getDateDiffDay(date: any) {
    const dateBegin = new Date(date.replace(/-/g, "/")); // 将-转化为/，使用new Date
    const dateEnd = new Date(); // 获取当前时间
    const dateDiff = dateEnd.getTime() - dateBegin.getTime(); // 时间差的毫秒数
    const dayDiff = Math.ceil(dateDiff / (24 * 3600 * 1000)); // 计算出相差天数，向上取整
    return dayDiff;
}

/**
 * 日期相差计算，得到相差天数
 * @param date 传过来时间和当前时间计算
 * @returns 计算后的相差的天数
 */
export function getDateDiffMonth(date: any) {
    const dateBegin = new Date(date.replace(/-/g, "/")); // 将-转化为/，使用new Date
    const dateEnd = new Date(); // 获取当前时间
    const dateDiff = dateEnd.getTime() - dateBegin.getTime(); // 时间差的毫秒数
    const monthDiff = Math.ceil(dateDiff / (24 * 3600 * 1000 * 30)); // 计算出相差月数，向上取整
    return monthDiff;
}

/**
 * 验证手机号码
 */
export function verifyPhone(mobile: string) {
    if (mobile === '' || mobile === 'undefined') {
        return false;
    }
    const myreg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    return myreg.test(mobile);
}

/**
 * 验证邮箱
 */
export function verifyEmail(email: string) {
    if (email === '' || email === 'undefined') {
        return false;
    }
    const emailReg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailReg.test(email);
}

/**
 * 设置cookie
 */
export function setCookie(cname: string, cvalue: string, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires + '; path=/';
}

/**
 * 获得某个cookie
 */
export function getCookieName(cname: string) {
    // 获取当前所有cookie
    const strCookies = document.cookie;
    // 截取变成cookie数组
    const arrCookies = strCookies.split(';');
    // 循环每个cookie
    let cookieDomain = '';
    arrCookies.forEach((arrItem) => {
        // 将cookie截取成两部分
        const item = arrItem.split("=");
        // 判断cookie的name 是否相等
        if (item[0].trim().toString() === cname) {
            cookieDomain = item[1];
        }
    });
    return cookieDomain !== '' ? cookieDomain : null;
}

/**
 * 统一设置获得个人信息需要缓存的数据localStorage和sessionStorage
 */
export function setLocalAndSession(resCurrent: any) {
    localStorage.setItem("userType", JSON.stringify(resCurrent.userType));
    localStorage.setItem("cert", JSON.stringify(resCurrent.certStatus));
    sessionStorage.setItem("currentPageUserType", JSON.stringify(resCurrent.userType));
}

/**
 * 金额格式化x,xxx.xx
 * @param s 金额
 * @param n 保留小数，默认2位
 */
export function moneyFormat(s: any, n: number = 2) {
    n = n > 0 && n <= 20 ? n : 2;
    s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
    const l = s.split(".")[0].split("").reverse();
    const r = s.split(".")[1];
    let t = "";
    for (let i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? "," : "");
    }
    return t.split("").reverse().join("") + "." + r;
}

/**
 * 复制一份get数据,如果为空，不组装起来
 */
export function copyGetParam(originParam: any) {
    const paramData: any = {};
    Object.keys(originParam).forEach((key) => {
        if (Object.prototype.toString.call(key) === '[object String]'
        && originParam[key] !== ""
        && originParam[key] !== "null"
        && originParam[key] !== "undefined") {
            paramData[key] = originParam[key];
        } else if (Object.prototype.toString.call(key) === '[object Number]') {
            paramData[key] = originParam[key];
        }
    });
    return paramData;
}

/**
 * 用来判断是否是360浏览器，没有很好的值，只能对比和chrome的不同点
 */
export function mime(option: any, value: any) {
    const mimeTypes: any = navigator.mimeTypes;
    for (const mt of Object.keys(mimeTypes)) {
        if (mimeTypes[mt][option] === value) {
            return true;
        }
    }
    return false;
}

/**
 * 用来判断是否限制价格输入
 */
export function priceParse(price: any) {
    let value = price;
    value = value
        .replace(/[^\d.]/g, "") // 将非数字和点以外的字符替换成空
        .replace(/^\./g, "") // 验证第一个字符是数字而不是点
        .replace(/\.{2,}/g, ".") // 出现多个点时只保留第一个
        .replace(".", "$#$") // 1、将数字的点替换成复杂字符$#$
        .replace(/\./g, "") // 2、将字符串的点直接清掉
        .replace("$#$", ".") // 3、将复杂字符再转换回点
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); // 只能输入两个小数
    if (value.indexOf(".") < 0 && value !== "") {
        value = parseFloat(value);
    } else if (value.indexOf(".") > 1 && value[0] === '0') {
        value = parseFloat(value);
    }
    // this.$set向响应式对象中添加一个属性，并确保这个新属性同样是响应式的，且触发视图更新
    // 调用方法：this.$set( target, key, value )
    // target：要更改的数据源(可以是对象或者数组)
    // key：要更改的具体数据
    // value ：重新赋的值
    return value;
}

/**
 * 用来判断是否是移动端
 */
export function isMobile() {
    const userAgentInfo = navigator.userAgent;
    const Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"];
    let flag = false;
    for (const item of Agents) {
        if (userAgentInfo.indexOf(item) > 0) {
            flag = true;
            break;
        }
    }
    return flag;
}

/**
 * 防抖函数
 */
let timer: any;
export function debounce(fn: any, wait: number) {
    return function(this: any) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(this, arguments);
        }, wait);
    };
}

/**
 * 通过token和token过期时间，用来初步判断token是否有效
 */
export function isValidToken() {
    const tokenExpiresTime: any = localStorage.getItem('id_token_expiresTime');
    const accessToken: any = localStorage.getItem('id_token');
    const hasLogged: boolean = accessToken !== null
        && accessToken !== undefined
        && accessToken !== ''
        && tokenExpiresTime !== null
        && tokenExpiresTime !== undefined
        && tokenExpiresTime !== ''
        && new Date(tokenExpiresTime).getTime() > new Date().getTime();
    return hasLogged;
}
