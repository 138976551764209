/**
 * @author xxx
 * @title  http请求封装
 * @desc
 */
import { Interceptors } from '@/service/interceptors';
import { useMessage } from '@/utils/message'; // 方便弹出吐司
import router from '@/router/index';
import { MessageBox } from 'element-ui';

export class HttpService {
  public axios: any;
  public modal: any;
  public isalert: boolean;

  constructor() {
    // 获取axios实例
    this.axios = new Interceptors().getInterceptors();
    this.isalert = false;
  }

  /**
   * get请求
   * @param paramsObj 参数
   * @param service 服务,默认services
   */
  public getData(paramsObj: object, service = 'services') {

    let url = service;
    if (process.env.NODE_ENV !== 'development') {
      url = url.substring(9, url.length);
    }
    const body = {
      data: paramsObj,
    };

    return new Promise((resolve, reject) => {
      this.axios.get(url, {
        params: paramsObj,
        //  headers: {isJwt: jwt},
      }).then((res: any) => {
        this.resultHandle(res, resolve, reject);
      }).catch((err: { message: any; }) => {
        reject(err.message);
      });
    });
  }
  /**
   * post请求
   * @param params 参数
   * @param service 服务,默认services
   * @param buyFlag 资源购买计算价格的时候，特殊处理。资源购买页buyFlag=1,加购页面buyFlag=2
   */
  public postData(params: object, service = 'services', headerTable = {}, buyFlag = 0) {
    let url = service;
    if (process.env.NODE_ENV !== 'development') {
      url = url.substring(9, url.length);
    }
    const body = {
      data: params,
    };
    return new Promise((resolve, reject) => {
      this.axios.post(url, params, {
       headers: headerTable,
      }).then((res: any) => {
        this.resultHandle(res, resolve, reject, buyFlag);
      }).catch((err: { message: any; }) => {
        reject(err.message);
      });
    });
  }
  /**
   * put请求
   * @param params 参数
   * @param service 服务,默认services
   */
   public putData(params: object, service = 'services', headerTable = {}) {
    let url = service;
    if (process.env.NODE_ENV !== 'development') {
      url = url.substring(9, url.length);
    }
    const body = {
      data: params,
    };
    return new Promise((resolve, reject) => {
      this.axios.put(url, params, {
       headers: headerTable,
      }).then((res: any) => {
        this.resultHandle(res, resolve, reject);
      }).catch((err: { message: any; }) => {
        reject(err.message);
      });
    });
  }
  /**
   * patch请求
   * @param params 参数
   * @param service 服务,默认services
   */
   public patchData(params: object, service = 'services', headerTable = {}) {
    let url = service;
    if (process.env.NODE_ENV !== 'development') {
      url = url.substring(9, url.length);
    }
    const body = {
      data: params,
    };
    return new Promise((resolve, reject) => {
      this.axios.patch(url, params, {
       headers: headerTable,
      }).then((res: any) => {
        this.resultHandle(res, resolve, reject);
      }).catch((err: { message: any; }) => {
        reject(err.message);
      });
    });
  }
  /**
   * delete请求
   * @param params 参数
   * @param service 服务,默认services
   */
   public deleteData(params: object, service = 'services', headerTable = {}) {
    let url = service;
    if (process.env.NODE_ENV !== 'development') {
      url = url.substring(9, url.length);
    }
    const body = {
      data: params,
    };
    return new Promise((resolve, reject) => {
      this.axios.delete(url, body, {
       headers: headerTable,
      }).then((res: any) => {
        this.resultHandle(res, resolve, reject);
      }).catch((err: { message: any; }) => {
        reject(err.message);
      });
    });
  }
  /**
   *
   * @param res
   * @param resolve
   */
  public resultHandle(res: any, resolve: any, reject: any, buyFlag = 0) {
    if (res.code === 200) {
      resolve(res.data || res);
    } else {
      this.errorHandle(res, reject, buyFlag);
    }
  }

   /**
    * 服务端状态处理,例如中断性异常,退出异常等等(与拦截器http握手状态注意区分,一般都能分清楚吧)
    * @param res
    */
  public async errorHandle(res: any, reject: any, buyFlag = 0) {
    // 状态码判断
    switch (res.code) {
      case 401:
      case 1001001001:
      case 1001001002:
      case 1001001004:
      case 1001001005:
      case 1001001006:
      case 1001001007:
        if (!this.isalert) {
          this.isalert = true;
          const that = this;
          MessageBox.alert('登录状态已过期，请重新登录', '提示', {
            showClose: false,
            confirmButtonText: '重新登录',
            callback: (action: any) => {
              // 清除各种缓存数据
              localStorage.clear();
              // 跳转到登录页面
              router.push("/login").catch((err) => err);
              that.isalert = false;
            },
          });
        }
        break;
      // 访问令牌失效，在得到最新token过期时间中使用，不提示到前台
      case 1001001003:
        break;
      case 1001001101:
        if (res.message && res.message !== '') {
          await useMessage(3, res.message);
        }
        // 清除各种缓存数据
        localStorage.clear();
        // 跳转到登录页面
        router.push("/login").catch((err) => err);
        break;
      case 3001:
      case 3002:
      case 3003:
      case 400004:
      case 400005:
        MessageBox.alert(res.message, '提示', {
          showClose: false,
          confirmButtonText: '确定',
          callback: (action) => {
            // ...
          },
        });
        break;
      // 资源不足 加购的时候判断
      case 400001:
      case 400002:
      case 400003:
        if (buyFlag !== 1) {
          MessageBox.alert(res.message, '提示', {
            showClose: false,
            confirmButtonText: '确定',
            callback: (action) => {
              // ...
            },
          });
        }
        break;
      // 资源不足 资源购买的时候判断
      case 3004:
      case 3005:
      case 3006:
        if (buyFlag !== 1) {
          MessageBox.alert(res.message, '提示', {
            showClose: false,
            confirmButtonText: '确定',
            callback: (action) => {
              // ...
            },
          });
        }
        break;
      case 400010: // 未领服务器
        if (buyFlag === 0) {
          useMessage(3, res.message);
        }
        break;
      case 400011: // 不同版本应用
      case 400013: // 服务器过期
        if (buyFlag !== 1) {
          useMessage(3, res.message);
        }
        break;
      case 1007000501:  // 产品申请试用弹窗
      case 1004004108:  // 产品体验弹窗
      case 1004013001:  // 项目申请，项目名重复
      case 1008500016:  // 驱动授权申请，项目名重复
      case 1002017000:  // 未登录状态查看文档链接
        break;
      case 1004006020:  // 个人用户无报备客户权限
        router.push("403").catch((err) => err);
        break;
      case 20051: // 首次购买4.0云服务器时，多次生成订单提示
        break;
      default:
        if (res.message && res.message !== '') {
          useMessage(3, res.message);
        }
        break;
    }
    reject(res);
  }
}
