export default[
    {
        path: 'redirectIframe',
        name: 'RedirectIframe',
        component: () => import(/* webpackChunkName: "orderManage" */ '@/views/company/orderManage/redirect/index.vue'),
    },
    {
        path: 'orderConfirm',
        name: 'OrderConfirm',
        component: () => import(/* webpackChunkName: "orderManage" */ '@/views/company/orderManage/confirm/index.vue'),
    },
    {
        path: 'myOrder',
        name: 'MyOrder',
        component: () => import(/* webpackChunkName: "orderManage" */ '@/views/company/orderManage/list/index.vue'),
    },
];
