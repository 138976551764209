import companyManage from './companyManage';
import trailManage from './trailManage';
import experienceManage from './experienceManage';
import resource from './resourceManage';
import orderManage from './orderManage';
import productAuthorize from './productAuthorize';
import demand from './demand';
import productDevelopment from './productDevelopment';
export default[
    {
        path: '/company',
        name: 'Company',
        redirect: {
          name: 'CompanyHome',
        },
        component: () => import(/* webpackChunkName: "company" */ '@/views/company/index.vue'),
        children: [
          {
            path: 'companyHome',
            name: 'CompanyHome',
            component: () => import(/* webpackChunkName: "company" */ '@/views/company/home/index.vue'),
          },
          {
            path: 'renewOrUpgrade',
            name: 'RenewOrUpgrade',
            component: () => import(/* webpackChunkName: "company" */ '@/views/company/renewOrUpgrade/index.vue'),
          },
          {
            path: 'certification',
            name: 'Certification',
            component: () => import(/* webpackChunkName: "company" */ '@/views/company/certification/index.vue'),
          },
          {
            path: 'message',
            name: 'Message',
            component: () => import(/* webpackChunkName: "company" */ '@/views/company/message/index.vue'),
          },
          ...companyManage,
          ...trailManage,
          ...experienceManage,
          ...resource,
          ...orderManage,
          ...productAuthorize,
          ...demand,
          ...productDevelopment,
          {
            path: 'redirectPage',
            name: 'RedirectPage',
            component: () => import(/* webpackChunkName: "company" */ '@/views/company/common/redirect/index.vue'),
          },
          {
            path: '500',
            name: 'Company500',
            component: () => import(/* webpackChunkName: "company" */ '@/views/500.vue'),
          },
          {
            path: '403',
            name: 'Company403',
            component: () => import(/* webpackChunkName: "company" */ '@/views/403.vue'),
          },
          {
            path: '*',
            name: 'CompanyNotFound',
            component: () => import(/* webpackChunkName: "company" */ '@/views/innerNotFound.vue'),
          },
        ],
    },
];
