import appManage from './appManage';
import saasManage from './saasManage';
import orderManage from './orderManage';
import financeManage from './financeManage';

export default[
    {
        path: '/sale',
        name: 'Sale',
        redirect: {
          name: 'SaleHome',
        },
        component: () => import(/* webpackChunkName: "sale" */ '@/views/sale/index.vue'),
        children: [
          {
            path: 'saleHome',
            name: 'SaleHome',
            component: () => import(/* webpackChunkName: "sale" */ '@/views/sale/home/index.vue'),
          },
          ...appManage,
          ...saasManage,
          ...orderManage,
          ...financeManage,
        ],
    },
];
