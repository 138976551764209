import { Message } from 'element-ui';

/**
 * 通过类型和具体信息返回相关类型的消息提示type：0、关闭；1、成功；2、错误；3、警告；4、提示
 * @param type
 * @param msg
 * @param duration  // 弹窗延迟关闭时间，默认3000ms；0表示不会自动关闭
 */
export function useMessage(type: number, msg: string = '', duration: number = 3000) {
  const Msg: any = Message;
  switch (type) {
    case 0:
      Msg.closeAll();
      break;
    case 1:
      Message({
        message: msg,
        type: 'success',
        duration,
      });
      break;
    case 2:
      Message.error(msg);
      break;
    case 3:
      Message({
        message: msg,
        type: 'warning',
        duration,
      });
      break;
    case 4:
      Message(msg);
      break;
    default:
      break;
  }
}
