export default[
    {
        path: 'finance',
        name: 'Finance',
        component: () => import(/* webpackChunkName: "financeManage" */ '@/views/sale/financeManage/finance/index.vue'),
    },
    {
        path: 'invoice',
        name: 'Invoice',
        component: () => import(/* webpackChunkName: "financeManage" */ '@/views/sale/financeManage/invoice/index.vue'),
    },
    {
        path: 'withdraw',
        name: 'Withdraw',
        component:
            () => import(/* webpackChunkName: "financeManage" */ '@/views/sale/financeManage/withdraw/index.vue'),
    },
];
