export default[
    {
        path: 'material',
        name: 'Material',
        component:
            () => import(/* webpackChunkName: "companyManage" */ '@/views/company/companyManage/material/index.vue'),
    },
    {
        path: 'customer',
        name: 'Customer',
        component:
            () => import(/* webpackChunkName: "companyManage" */ '@/views/company/companyManage/customer/index.vue'),
    },
    {
        path: 'security',
        name: 'Security',
        component:
            () => import(/* webpackChunkName: "companyManage" */ '@/views/company/companyManage/security/index.vue'),
    },
    {
        path: 'address',
        name: 'Address',
        component:
            () => import(/* webpackChunkName: "companyManage" */ '@/views/company/companyManage/address/index.vue'),
    },
    {
        path: 'staff',
        name: 'Staff',
        component:
            () => import(/* webpackChunkName: "companyManage" */ '@/views/company/companyManage/staff/index.vue'),
    },
    {
        path: 'organization',
        name: 'Organization',
        component:
            () =>
            import(/* webpackChunkName: "companyManage" */ '@/views/company/companyManage/organization/index.vue'),
    },
];
