const BASE_PROXY = '/apiProxy';
const apiList = {
    // 登录
    login: BASE_PROXY + '/api/frontend/passport/login',
    // 短信登录
    smsLogin: BASE_PROXY + '/api/frontend/passport/sms/login',
    // 登录短信图形验证码
    smsCaptcha: '/api/frontend/passport/login/captcha.jpg',
    // 登出
    logout: BASE_PROXY + '/api/frontend/passport/logout',
    // 注册号码预校验
    precheck: BASE_PROXY + '/api/frontend/passport/register/precheck',
    // 图形验证码
    captcha: '/api/frontend/passport/register/captcha.jpg',
    // 获得注册session
    register_session: BASE_PROXY + '/api/frontend/passport/register/session',
    // 发送注册短信验证码
    register_sms: '/api/frontend/passport/register/sms/captcha',
    // 注册
    register: BASE_PROXY + '/api/frontend/passport/register',
    // 忘记密码图形验证码
    forgetCaptcha: '/api/frontend/passport/retrieve/captcha.jpg',
    // 当前登录用户
    userInfo: BASE_PROXY + '/api/frontend/users/current',
    // 切换账号类型登陆
    switcHover: BASE_PROXY + '/api/frontend/passport/login/switchover',
    // 收货地址列表
    address_list: BASE_PROXY + '/api/frontend/receiving-address',
    // 地址详情
    address_detail: BASE_PROXY + '/api/frontend/receiving-address/%s',
    // 修改地址
    address_update: BASE_PROXY + '/api/frontend/receiving-address/%s',
    // 设置默认地址
    address_set_default: BASE_PROXY + '/api/frontend/receiving-address/%s/default',
    // 删除地址
    address_delete: BASE_PROXY + '/api/frontend/receiving-address/%s',
    // 添加地址
    address_add: BASE_PROXY + '/api/frontend/receiving-address',
};
export default apiList;
