import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menuesData: [], // 菜单栏数据
    currentPath: "companyHome",
    renzheng: 0,
    userInfo: {},
  },
  mutations: {
    // 保存用户信息
    saveUserInfo(state, userData) {
      state.userInfo = userData;
    },
    // 保存所有菜单栏数据
    saveMenuData(state, menuesData) {
      state.menuesData = menuesData;
    },
    // 保存当前菜单路由
    saveCurrPath(state, currentPath) {
      state.currentPath = currentPath;
      localStorage.setItem("currentPath", currentPath);
    },
    // 保存认证状态
    saveRenzheng(state, renzheng) {
      state.renzheng = renzheng;
    },
  },
  actions: {
  },
  modules: {
  },
});
