export default[
    {
        path: 'ecoPartner',
        name: 'EcoPartner',
        component: () => import(/* webpackChunkName: "productDevelopment" */ '@/views/company/productDevelopment/ecoPartner/index.vue'),
    },
    {
        path: 'driveDevelopment',
        name: 'driveDevelopment',
        component: () => import(/* webpackChunkName: "productDevelopment" */ '@/views/company/productDevelopment/drive/index.vue'),
    },
    {
        path: 'profitManage',
        name: 'profitManage',
        component: () => import(/* webpackChunkName: "productDevelopment" */ '@/views/company/productDevelopment/profit/index.vue'),
    },
];
