import accountManage from './accountManage';
import experienceManage from './experienceManage';

export default[
    {
        path: '/person',
        name: 'Person',
        redirect: {
          name: 'PersonHome',
        },
        component: () => import(/* webpackChunkName: "person" */ '@/views/person/index.vue'),
        children: [
          {
            path: 'personHome',
            name: 'PersonHome',
            component: () => import(/* webpackChunkName: "person" */ '@/views/person/home/index.vue'),
          },
          {
            path: 'reportCustomer',
            name: 'PersonReportCustomer',
            component: () => import(/* webpackChunkName: "person" */ '@/views/person/reportCustomer/index.vue'),
          },
          ...accountManage,
          ...experienceManage,
          {
            path: '500',
            name: 'Person500',
            component: () => import(/* webpackChunkName: "person" */ '@/views/500.vue'),
          },
          {
            path: '403',
            name: 'Person403',
            component: () => import(/* webpackChunkName: "person" */ '@/views/403.vue'),
          },
          {
            path: '*',
            name: 'PersonNotFound',
            component: () => import(/* webpackChunkName: "person" */ '@/views/innerNotFound.vue'),
          },
        ],
    },
    {
      path: '/bindMc',
      name: 'BindMc',
      component: () => import(/* webpackChunkName: "person" */ '@/views/person/singlePage/bindMc/index.vue'),
    },
];
